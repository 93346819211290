const onboarding = {
    Optional: "（可选）",
    submit:'提交',

    // onboarding type
    chooseYourApplicationMethod:'请选择您的申请方式',
    onlineOnboarding:'线上开户',
    applicationMethod:'申请方式',
    onboardingProcessMessage:'您可以在线完成开户流程，或通过提交申请表格进行开户。',
    formOnboarding:'填表开户',
    start:'开始',
    entityOnboarding:'公司开户',
    individualOnboarding:'个人开户',
    noOnboardingFormMessage:'如您还没有我们的开户表格，请下载以下文件和表格以获取更多详细信息。',
    checklist:'检查表',
    onboardingForm:'开户表格',
    download:'下载',
    unsavedChangesWarning:'请您留意，当您离开或刷新该网页将无法保存当前进度。',

    // form onboarding review
    confirmation:'确认',
    submittedDocuments:'文件已提交',
    thankYouForUpdatingInfo:'感谢您向 VirgoCX Direct Inc. 提交资料。我们的客户经理将初步审核您的开户申请，如需更多信息，我们将通过电话与您联系。同时，您可点击以下链接以查看您的申请状态或完善您的申请提交。您也可以致电我们并提供您的邮箱地址来查询。',
    
    // account type
    accountType:'账户类型',
    accountTypeDesc:'根据法规的要求，我们需要验证您的身份，以便您可以安全地开始交易。',
    pleaseSelectAccType:'请选择您的账户类型',
    individualAcc:'个人账户',
    entityAcc:"企业账户",
    next:'下一项',

    //memu
    menuDesc:'请按照以下步骤完成申请',
    firstStep:'提交您的信息',
    minutes:'分钟',
    requiredBy:'法规要求',
    secondStep:'确认声明',
    thirdStep:'提交您的ID照片',
    fourthStep:'检查并提交',
    continue:'继续',

    //welcome
    Welcome:'欢迎',
    welcomeDesc:'在开始之前，请提供您的信息',
    startNow:'立即开始',

    //entity
    subStep1:"1. 提交信息",
    subStep1c:'请提供公司信息',
    EntityAccount:'公司账户',
    SignificantIndividual:'重要个人',
    Documents:'文件',
    BeneficialOwnership:'收益所有权和第三方声明',
    Disclosure:'披露',
    Review:'审核',

    //A. corporation info
    corporateInfo:'A. 公司信息',
    legalName:'公司法定名称',
    registrationNumber:'公司注册号',
    businessId:'公司识别号码',
    businessIdContent:'公司识别号码，税号，或其他公司ID',
    website:'公司网站（选填）',
    corporateCountry:'公司成立国家',

    //B. contact info
    contactInfo:'B. 联系信息',
    Describe:'请描述公司业务功能， 和/或提供的产品和服务',
    requireSignature:'该账户交易需要多少签名',
    BuildingNumber:"大楼号码",
    StreetAddress:"街道地址",
    unitNumber:"公寓门牌号，单元号（选填）",
    City:"城市",
    country:'国家/地区',
    Province:"州/省",
    PostalCode:"邮政编码",
    PhoneNumber:'电话号码',
    email:'电子邮件',
    coutryFormation:'Country Of Legal Formation',

    //C. Business Activities
    BusinessActivities:'C. 公司业务活动',
    Primary:'主要业务',
    Secondary:'次要业务（选填）',
    Tertiary:'其他业务（选填）',
    PrimarySrc:'主要资金来源',
    businessActivitiesDes:'Describe business function, and/or products and services offered',

    //D. Business Registrations
    BusinessRegistrations:"D. 公司注册信息",
    question1:"您的公司是否在任何联邦、州和/或省监管机构注册或受监管？",
    yes:'是',
    no:'否',
    question1Desc:"如果是，请提供所有监督您公司活动的机构名称",
    OversightAuthorities:"公司监管机构",
    question2:"您的公司是否以货币服务商或证券交易商的身份运营？",
    question3:"如果是，则您的公司是否使用OFAC/加拿大/联合国/本地等发布的个人/实体名单（特别指定国民名单/特定人员名单和制裁法）来限制向其提供金融服务或与其进行金融交易的（例如涉嫌恐怖分子、洗钱者）？",

    //E. Source of Fund
    titleE: "E. 资金来源",
    income:"收入（工作储蓄）",
    giftH:"请提供赠予者的信息",
    pastInvestmentH:"请提供过去投资的信息",
    investmentType:"投资类型",
    investmentDate:"投资日期（年-月-日）",
    investmentAmount:"投资金额",
    investmentProfit:"投资收益",
    sofOther:"请解释",
    Address:"居住地址",
    Relationship:"和客户的关系",
    JobTitle:"工作职位",
    Employer:"雇主名称",
    Income:"收入",
    JobFunction:"工作职能",
    SourceofFunds:"资金来源",
    GiftValue:"赠予金额",
    GiftPurpose:"赠与目的",
    DateofBirth: "出生日期（年-月-日）",

    //F. Purpose of Account
    titleF: "F. 账户用途",
    FriendlyReminder:'友情提醒',
    modalTxt:'一旦您进入到下个部分，您将无法再更改您的开户形式.',
    confirm:'确认',
    changeMind:'我再想想',

    //2.  Significant Individual
    SignificantIndividual:"2.  重要个人",
    save:'保存',
    formEmpty: '添加下一位前请完成当前内容的填写',
    NewIndividual: "新的个人",
    areYousure:'确认删除该个人信息？',
    pleaseConfirm:"请确认所有信息是完整的",
    kindlyConfirm:'请在提交前确保所有信息是完整的',
    sthMissing:'缺失一些信息',
    toAddPerson:'请在添加新的个人信息前，确保当前所有个人信息填写完整',
    ok:'Ok',
    addNewPerson:'添加新的个人',
    toSubmit:'请在提交信息前确保所有当前个人信息填写完整',

    //3. documents
    Documents: '3. 所需文件',
    step3subtitle: "请按照指示提供对应的文件",
    docRequire: '所需文件',
    uploadAll: '请在此处上传全部所需文件',
    fileOneTitle: '组织结构图/公司所有权/控制结构图',
    fileOneSubTitle: '在实体入职表中提到的所有受益人和董事必须显示在这张图中，总数应为公司100%的股份',
    fileTwoTitle: '股东协议',
    fileTwoSubtitle: '必须包括所有当前股东的姓名和签名',
    fileThreeTitle: 'Schedule 50',
    fileThreeSubtitle: '通常由CRA要求',
    fileFourTitle: '董事会决议书',
    fileFourSubtitle: '每个公司都有所不同，请咨询您的客户经理已获得更多信息',
    fileFiveTitle: '公司章程/公司注册证书',
    fileFiveSubtitle: '由地方政府发行，包括公司名称，编号，地址和董事信息',
    fileSixTitle: '交易协议（可选）',
    fileSixSubtitle: '包括交易规则，隐私和所有其他相关条款，如果客人想要使用我们的OTC Direct平台交易或持有我们的零售平台账户必须签署此协议',
    fileSevenTitle: 'AML/KYC政策（可选）',
    fileSevenSubtitle: '所有MSB公司或金融机构都需要，如果您是持有客户资产的货币服务商或金融机构， 请向我们提供您的AML/KYC政策',
    fileEightTitle: '银行账户电汇信息',
    fileEightSubtitle: '可以是银行预授权支付表格、空白支票或任何包含银行名称和地址、您的姓名和地址、机构号、转账号和SWITF代码信息',
    fileNineTitle: '其他相关文件（可选）',
    fileNineSubtitle: '修改表单、信息解释、投资记录、银行对账单和您想要添加以支持您交易的任何信息',
    Attention: '注意',
    attention1: '所有上述文件的PDF版本需要直接来自于源出处，我们不接受修改后的文件',
    attention2: '对于文件3至文件5，必须包含公司印章/签名或写在公司的抬头纸上， 同时这三份文件中只需要最多提供一份即可',
    attention3: '请确保文件1中的名字和地址与文件8中的一致，否则，需要提供相关文件至文件9以解释姓名和/或地址差异的原因',
    attention4: '在上述文件中列出的所有人都必须提供ID用于身份验证，我们将在收到这个文件后发送验证身份的链接',
    attention5: '如有任何问题请及时联系您的客户经理',


    // 4. Beneficial Ownership and Third Party Attestation
    entityStep4LH:"4. 收益所有权和第三方声明",
    Attestation:"声明",
    Attestation1_1_0:"我，",
    Attestation1_1:"已经进行过或正在进行我认为有必要的检查或调查，以便对本声明中包含的陈述进行核实，我提供本证明的目的是让VirgoCX Direct Inc可以信赖我声明的内容",
    Attestation1_2:"附在本声明上的内容是真实，完整和准确的",
    Attestation1_2_a_1:"公司股东登记簿 （以下简称“股东登记簿”）；和",
    Attestation1_2_b_1:"公司的组织结构图，详细说明公司的所有权和控制结构（组织结构图）",
    Attestation1_3:"组织结构图详细说明了公司所有权和控制的真实、完整和当前信息",
    Attestation2:"股东登记簿包含了公司发行的所有证券和权证的真实、完整和当前记录。 公司：",
    Attestation2_1:"没有授予任何其他人公司证券的转换特权、选择权或其他权利",
    Attestation2_2:"已经授予公司证券的转换特权、选择权或其他权利给以下人员",
    Attestation2_2_input:"请详细阐述",
    Attestation3_1:"公司不发行无记名股票。如果股东登记簿显示任何非个人已注册证券或权证持有人，并且拥有公司25%或更多股份的，附在本声明上的还有该公司（以及控制该公司的任何实体）的股东登记簿（或等效文件），该股东登记簿包含了该实体发行的所有证券和权证的真实、完整和当前记录。",
    Attestation3_2:"就本表格附件中包含的个人信息而言，我已经获得了所有相关个人的必要同意，以允许VirgoCX Direct Inc.为VirgoCX Direct Inc.隐私政策中描述的目的收集、使用和披露个人信息。 ",
    Attestation4:"在与VirgoCX Direct Inc的交易中：",
    Attestation4_1:"公司不参与第三方交易",
    Attestation4_2:"公司参与第三方交易",
    disclosureT:"5. 披露",

    // 6. Review
    reviewH:"6. 审查",
    reviewC:"请提供您的个人信息",
    edit:"编辑",
    reviewFile:'查看文件',
    individual: '个人',
    submitApplication:'上传申请',

    // onboarding type modal
    readyToCompleteOnboarding:'准备好完成您的开户申请了吗？',
    onboardingTypeModalH:'在您继续前，请您确保您已准备好以下信息及文件：',
    onboardingTypeModal1:'已完成的开户表格',
    onboardingTypeModal2:'相关文件（如，身份证明,地址证明）',
    onboardingTypeModal3:'任何额外所需的信息',

    //success page
    Successfully:'上传成功',
    refNumber:'您的申请号：',
    successContent:"感谢您上传到VirgoCX Direct Inc.的开户申请。我们的一位客户经理将查看您的开户信息，如果需要更多的资料，他们将会及时联系您。如果查看后所有信息都正确无误，您可以使用下面的链接登录回来签署和提交您的申请。请记住您的申请号，因为这对于检查您的申请状态或完成最终提交非常重要。 ",
    CustomerService:'我们的客户服务电话',
    businessHours:'我们的客户服务时间',
    hours:'周一至周五：9:00 am - 5:00 pm',
    CheckStatus:'查看账户状态',
    enterEmail:"输入邮箱地址",
    formatIncorrect:"邮箱格式不正确",
    Back:'返回'
};
export default onboarding;