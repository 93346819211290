const personalInfo = {
    test: "test",
    subStep1: "1.  Submit Your information",
    subStep1c: "Kindly provide your personal information.",
    Back: "Back",
    PersonalInformation: "A. Personal Information",
    FirstName: "First Name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    Email: "Email",
    DateofBirth: "Date of Birth (yyyy-mm-dd)",
    Nationality: "Nationality",
    APP: "Instant Message App",
    otherAPP: "Other App",
    APPID: "App ID",
    Optional: " (Optional)",
    Next: "Next",
    Submit:"Submit",

    titleB: "B. Residence Address",
    BuildingNumber:"Building Number",
    StreetAddress:"Street Address",
    unitNumber:"Apt, Suite or Unit (Optional)",
    City:"City",
    country:'Country/Region',
    Province:"State / Province",
    PostalCode:"Postal Code / ZIP code",
    hasMailingAddress:"Is your mailing address the same as your residential address?",
    Yes:"Yes",
    No:"No",

    titleC: "E. Employment Information",
    PrimaryEmployment:"Current / Latest Employment",
    CurrentEmploymentStatus: "Current Employment Status",
    YearStart: "Year Start",
    YearEnd: "Year End",
    now:"Now",
    Industry: "Industry",
    Countryofemployment: "Country of Employment",
    Nameofemployer: "Name of Employer",
    TitleRole: "Title / Role",
    Department: "Department",
    EmployerWebsite: "Employer Website",
    SalaryRange: "Salary Range",
    OtherEmployment: "Other Employment",
    SecondaryEmployment: "Former Employment",

    titleD: "C. Source of Fund",
    sourceOfFundh:"Please select all applicable source(s) of funds:",
    income:"Income (Savings of Employment)",
    giftH:"Please provide the Gifter's information.",
    pastInvestmentH:"Please provide the Past investment's information.",
    investmentType:"Investment Type",
    investmentDate:" Investment Date (yyyy-mm-dd)",
    investmentAmount:"Investment Amount",
    investmentProfit:"Investment Profit",
    sofOther:"Please explain",
    Address:"Address",
    Relationship:"Relationship",
    JobTitle:"Job Title",
    Employer:"Employer",
    Income:"Income",
    JobFunction:"Job Function",
    SourceofFunds:"Source of Funds",
    GiftValue:"Gift Value",
    GiftPurpose:"Gift Purpose",


    titleE: "D. Purpose of Account",
    FriendlyReminder:'Friendly Reminder',
    modalTxt:'Once you move to next section, you will no longer be able to change your application type.',
    confirm:'Confirm',
    changeMind:'I changed my mind',
    later:`Later`,
    modalJumio:`After clicking 'Confirm,' kindly proceed to complete the online verification process in a new tab.`,

    titleF: "F. Attestation",
    q1:"Have you ever been sanctioned by the United Nations, any Self-Regulatory Organization, any National Competent Authority, or other financial regulator?",
    q2:"Are you considered to be a Politically Exposed Person (PEP), a politically exposed foreign person (PEFP); a politically exposed domestic person (PEDP); a head of an international organization (HIO); or a Family Member or Close Associate of such person (collectively, PEPs)?",
    yes:"Yes",
    no:"No",
    Fconfirm1:"I, ",
    Fconfirm2:", confirm that any fiat or virtual currency transfer instructions will reflect account(s) or wallet(s) which are owned and controlled by me. Furthermore, I agree I will not use my account to transact on behalf of third parties.",

    subStep2: "2. Identity Verification",
    subStep2c: "Choose your method of identity verification",
    subStep2manual:"Please provide documents as instructed,",
    verificationTitle:"Please choose one of the following methods to verify your identity and follow the appropriate steps:",
    OnlineIdentityVerification:"Online Identity Verification",
    ManualVerification:"Manual Verification",
    InPersonVerification:"In Person Verification",
    DocumentsRequired:"Documents Required",
    manualC:"Please follow the instructions to submit the required documents.",
    file1:"Identification (Passport, Driver Licence front and back or other government issued ID)",
    file2:"Proof of Address (Within 3 months original PDF version utility bill or bank statement)",
    file3:"Support Documents",

    subStep3: "3. Acknowledge Statement",
    subStep3c: "Please read and agree to the following terms.",
    Agree:"Agree",
    acknowledgeError: `Please scroll down to the bottom, read the whole document, and click Agree. `,

    subStep4: "3. Review",
    subStep4c: "Kindly provide your personal information.",

    edit:"Edit",
    cancel:"Cancel",
    reviewFile:"Review File",
    submitApplication1:"Upload",
    accepted:"Accepted",
    Submitted:"Submitted",

    NewIndividual:"New Individual",
    formTitle:'Please provide information as below',
    middleName:"Middle Name (Optional)",
    address:"Address",
    email:"Email",
    formTitle2:"Is this individual a Politically Exposed Person (PEP) as defined by FATF guidelines?",
    relevantroles:"Please choose all relevant roles.",
    Reminder:"Reminder",
    BeneficialOwners:'Beneficial Owners',
    BeneficialOwnersContent:'Please provide the information for all beneficial owners who own directly or indirectly 25% or more. If any of the corporation’s shareholders is an entity, then please also attach an ownership chart.',
    Director:"Director",
    AuthorizedOfficer:'Authorized signing officer',
    confirmAuthorizedOfficer:"Please confirm the authorized signing officer.",
    AuthorizedTrader:"Authorized Trader",
    IndividualWithControl:"Individual with significant control",
    IndividualWithControlContent:"An individual with significant control is defined as an individual who: owns, controls, or directs a significant number of shares; or has significant influence over the corporation without owning any shares; or has a combination of any of these factors. Please note that you do not have to select this if you already selected other roles within this onboarding.",
    Ownership:"% of Ownership",
    Occupation:'Occupation',

    //entity person
    Individualverification:"Individual ID verification",
    individualSubTitle:'Please choose one of the following methods to verify your identity and follow the appropriate steps:',
    otherIdVerification:"Online Identity Verification",
    manual:'Manual Verification',
    inPerson:'In Person Verification',
    uploadDocs:'Upload Documents',
    Identification:'Identification',
    individualSubTitle2:'Passport, Driver Licence front and back or other government issued ID',
    ProofofAddress:'Proof of Address',
    individualSubTitle3:'Within 3 months original PDF version utility bill or bank statement',
    Methods:'Methods of identification',
    idVerification:'Identification (Passport, Driver Licence front and back or other government issued ID)',
    proofOfAddressTitle:'Proof of Address (Within 3 months original PDF version utility bill or bank statement)',
    submitApplication:'Upload Application',

    // id verification des:
    idVerificationMethod:'ID verification method',
    chooseVerificationMethod:'Please choose your method of identity verification.',
    onlineDes:`There will be a link generated directly after you submitted your documents, and please click the link and follow instruction for your ID and identity verification.\n
    Suggest to use a device with front/rear camera functions to complete your identity verification.`,
    manualDes:'You will be requested to upload your ID and proof of address in next section',
    inPersonDes:'If you met or plan to meet one of our Account Managers, please choose this one; otherwise, please choose one of above two methods.',
    uploadRequiredFiles:'Please upload required files below',
    onboardingForm:'Onboarding Form',
    uploadDocuments:'Upload Documents',
    providePersonalInformation:'Kindly provide your personal information.',
    idVerificationT:'ID Verification',
    proofOfAddress:'Proof of Address',
    supportDocumentsOptional:'Support Documents (optional)',
    onboardingFormTip:"VirgoCX Direct Individual/Entity Onboarding Form, please make sure you filled all mandatory fields and signed",
    idTip:"Both front and/or back side of your Driver License, Passport or other government issued ID",
    poaTip:"Original PDF version utility bill or bank statement under your name and address within 3 months",
    supTip:'All other documents',

    // company docs
    companyDocs: "Company Documents",
    companyDocsReminder: "Please upload your Organization Chart, Shareholder Agreement, Article of Incorporation and/or other equalized documents, check with your Account Manager or call our customer service for more details.",

    // bank information
    bankInfo: "Bank Information",
    bankInfoReminder: "Detailed bank wire information (Check with your account manager for different countries’ requirements)"
};
export default personalInfo;
