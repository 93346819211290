const loginRegister = {
    login: "Log In",
    signup: "Sign Up",
    emailAddress: "Email Address",
    password: "Password",
    firstName: "First Name",
    lastName: "Last Name",
    confirmPassword: "Confirm Password",
    phoneNumber: "Phone Number",
    forgetPwd:'Forgot Password?',
    signupCheckbox:'I am looking to transact $50,000 CAD or more in cryptocurrencies',
    termsCheckbox1:"I have read and agree to the ",
    termsCheckbox2:"Terms of Service",
    termsCheckbox3:" and ",
    termsCheckbox4:"Privacy Policy",
    termsCheckbox5:".",
    welcome:'Welcome to VirgoCX Wealth',
    formatIncorrect:"The email format is incorrect.",
    enterEmail:"Please enter the Email.",
    rule6:"Please enter English only!",
    symbolsAreAllowed:"Need to be 8-15 characters, symbols are allowed.",
    onletter:"At least one number and one letter.",
    doesNotMatch:"Password does not match.",
    emailVerification:"Email Verification",
    codeSentToEmail:"<p>Please enter the code sent to your email</p>",
    registrationCompleted:"Registration completed.",
    back:'Back',
    yourRegisteredEmail:"Please provide your registered email",
    forgotpwd:'Forgot Your Password',
    newPassword:"New Password",
    confirmNewPassword:"Confirm Your New Password",
    successfullyModified:"The login password was successfully modified.",
    resetPassword:"Reset Password",
    createNewPassword:"Please create your new password.",
    Confirm:'Confirm',
    googleCode:'Google / Microsoft Authenticator Code',
    codeRule: `Please enter 6 numbers`,
    codeRulePhone: `Please enter a valid phone number with 10 numbers`,
    GoogleAuthentication:`Authentication App`,
    GoogleAuthenticatorApp:`Please enter the code on your Google/Microsoft authenticator app.`,
    rule1: `Need to be 10 numbers.`,
    rule2: `Need to be numbers, symbols and letters are not allowed`,
    rule3: `Need to be 8-15 characters, symbols are allowed.`,
    rule4: `At least one number and one letter.`,
    CurrentPassword: `Current Password`,
    NewPassword: `New Password`,
    enterEmailVerificationCode:'Please enter the Email verification code',
    enterSMSVerificationCode:"Please enter the SMS verification code",
    loginSuccessful:"Login Successful.",
    reLogin:`Login Again`,
    smsVerification:'SMS Verification',
    codeSentTo:`Please enter the code sent to +1 {{value}}`,
};
export default loginRegister;
