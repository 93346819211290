const loginRegister = {
    login: "登录",
    signup: "注册",
    emailAddress: "电子邮件地址",
    password: "密码",
    firstName: "名字",
    lastName: "姓氏",
    confirmPassword: "确认密码",
    phoneNumber: "电话号码",
    forgetPwd:'忘记密码？',
    signupCheckbox:'我打算在加密货币中进行50000加币或更多的交易',
    termsCheckbox1:"我已阅读并同意",
    termsCheckbox2:"服务条款",
    termsCheckbox3:"和",
    termsCheckbox4:"隐私政策",
    termsCheckbox5:"。",
    welcome:'欢迎使用VirgoCX财富',
    formatIncorrect:"电子邮件格式不正确",
    enterEmail:"请输入电子邮件",
    rule6:"请仅输入英文！",
    symbolsAreAllowed:"需要8-15位字符，允许使用符号",
    onletter:"至少包含一个数字和字母",
    doesNotMatch:"密码不匹配",
    emailVerification:"电子邮件验证",
    codeSentToEmail:"<p>请输入发送到您电子邮件的验证码</p>",
    registrationCompleted:"注册完成",
    back:'返回',
    yourRegisteredEmail:"请提供您的注册邮件",
    forgotpwd:'忘记密码',
    newPassword:"新密码",
    confirmNewPassword:"确认新密码",
    successfullyModified:"您的登录密码已成功更改",
    resetPassword:"重置密码",
    createNewPassword:"请创建您的新密码",
    Confirm:'确认',
    googleCode:'Google / Microsoft Authenticator验证码',
    codeRule: `请输入6位数字验证码`,
    codeRulePhone: `请输入10位手机号码`,
    GoogleAuthentication:`身份验证应用`,
    GoogleAuthenticatorApp:`请输入您Google / Microsoft Authenticator上的验证码`,
    rule1: `需要10位数字`,
    rule2: `只能是数字，不允许使用符号和字母`,
    rule3: `需要8-15个字符，允许使用符号`,
    rule4: `至少包含一个数字和字母`,
    CurrentPassword: `当前密码`,
    NewPassword: `新密码`,
    enterEmailVerificationCode:'请输入电子邮件验证码',
    enterSMSVerificationCode: "请输入手机短信验证码",
    loginSuccessful:"登录成功",
    reLogin:`重新登录`,
    smsVerification:'短信验证',
    codeSentTo:`请输入发送至+1 {{value}}的验证码`,
};
export default loginRegister;
