import serviceAxios from "./index";

export default class MemberProfileAPI {

    /**
     * 获取用户信息
     */
    static getUserProfile() {
        return serviceAxios.get(`wealthv2/member/profile`)
    };

    // onboarding type
    static updateOnboardingType(type){
        return serviceAxios.post(`wealthv2/member/updateOnboardingType/${type}`)
    };
    
    static logout() {
        return serviceAxios.post("/wealthv2/member/logout");
    }
    

    // /提交用户信息
    static saveMemberInfoStepOne(data){
        return serviceAxios.post('wealthv2/member/saveMemberInfoStepOne',data)
    };
    static saveMemberInfoStepTwo(data){
        return serviceAxios.post('wealthv2/member/saveMemberInfoStepTwo',data)
    };
    static saveMemberInfoStepThree(data){
        return serviceAxios.post('wealthv2/member/saveMemberInfoStepThree',data)
    };
    static saveMemberInfoStepFour(data){
        return serviceAxios.post('wealthv2/member/saveMemberInfoStepFour',data)
    };
    //entity review页面提交信息
    static saveMemberInfoStepSix(data){
        return serviceAxios.post('wealthv2/member/saveMemberInfoStepSix',data)
    };
    static saveMemberInfo(data){
        return serviceAxios.post('wealthv2/member/saveMemberInfo',data)
    };

    //用户签名
    static sign(data){
        return serviceAxios.post(`wealthv2/member/submit?sign=${data}`)
    };

    //企业用户签名
    static entitySign(data){
        return serviceAxios.post(`wealthv2/member/submitEntity?sign=${data.signature}&title=${data.title}`)
    };

    /**
     * 查询用户安全中心首页
     */
    static getSecurityInfo() {
        return serviceAxios.get(`wealthv2/member/security/index`)
    };

    // 需要签的合同
    static memberNeedAcknowledge() {
        return serviceAxios.get(`wealthv2/member/memberNeedAcknowledge`)
    };

    // 合同
    static AcknowledgementList() {
        return serviceAxios.get(`wealthv2/member/AcknowledgementList`)
    };

    /**
     * 获取已经签过的terms列表
     */
    static getTermsList(){
        return serviceAxios.get('/wealthv2/member/memberAcknowledgement')
    };

    //已签合约用户统一新合同
    static agreeAcknowledgement(data){
        return serviceAxios.post('/wealthv2/member/agreeAcknowledgement',data)
    };

    //已签合约用户统一新合同
    static getJumioLinkList(){
        return serviceAxios.get('/wealthv2/member/jumioLinkList')
    };


} 