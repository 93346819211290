const tradeConfirmation = {
    manual: "报价交易",
    otcDirectSettlement: "OTC Direct 结算",
    otcDirectDeposit:'OTC Direct 入金',
    tradeConfirmationRecord: "交易确认记录",
    // transactionConfirmationDisclaimer
    transactionConfirmationDisclaimer: "交易确认免责声明",
    transactionConfirmationDisclaimer1:
        "这些确认信息可能包含保密和（或）法律特权信息。如果您不是交易人，请通过电话通知交易人或忽略此确认信息。除交易人外，任何人都不得使用或分发此确认信息。",
    transactionConfirmationDisclaimer2:
        'VirgoCX Direct Inc. (“VirgoCX”) 严禁涉及未知或未披露第三方的交易。向我们提供交易指令,即表示您承认已向VirgoCX披露所有参与的第三方。由于投资骗局和其他类型的欺诈活动盛行，许多加密货币投资者遭受大量的损失。向我们提供交易指令，即表示您承认VirgoCX不对VirgoCX控制范围之外的损失负责。一旦您的加密货币按照您的指示从我们的钱包转移到受益人的钱包，VirgoCX就无法再控制您的加密货币。通过向我们提交交易指令，您同意赔偿VirgoCX在您的加密货币离开VirgoCX控制的钱包后发生的损失。',
    confirm: "确认",
    deny: "拒绝",

    // table
    dateAndTime:'日期与时间',
    tradeId:'交易号',
    tradeInformation:'交易信息',
    status:'状态',
    action:'操作',

    newTrade:'New Trade',
    confirmed:'Confirmed',
    paymentProcessed:'Payment Processed',
    cancelled:'Cancelled',
    rejected:'Rejected',
    pendingReject:'Pending Reject',
    tradeUpdate:'Trade Update',

    // detail
    tradeConfirmation:'交易确认',
    tradeId:'交易号',
    status:'状态',
    statusUpdateTime:'状态更新时间',
    note:'备注',
    confirmTradeMessage:'请在下方确认交易信息并按照指示执行以下交易内容。',
    thankYouMessage:'感谢您选择VirgoCX。',
    confirmYourTransaction:'确认您的交易',
    inputConfirmHere:'请在此处输入“确认”。',
    inputRejectPrompt:'请在此处输入“拒绝”。',
    confirm:'确认',
    reject:'拒绝',
    typeHere:'在此输入',
    cancel:'取消',
    process:'处理',

    hiContext: "Hi {{clientName}},",
    tradeConfirmationHeader: "Here is your trade confirmation.",
    dateTimeContext: "Date/Time of Transaction: ",
    clientNameContext: "Client Name: {{clientName}}",
    actionContext: "Direction: ",
    digitalCurrencyContext: "Currency: ",
    fiatCurrencyContext: "Counter Currency: ",
    totalCryptoAmountContext: "Quantity (to be) Received by Buyer: ",
    pricePerTokenContext: "Price Per Token: ",
    totalFiatAmountContext: "Total Fiat Currency Deliverable to Seller: ",
    receivingWalletContext: "Buyer's Wallet Address: ",
    wireTransferInformationContext: "Seller's Wire Transfer Instructions: ",

    transactionConfirmed:'交易已确认',
    transactionConfirmedMessage:'谢谢！您的交易已成功确认。',
    transactionRejected:'交易已拒绝',
    transactionRejectedMessage:'您已成功拒绝该交易。没有任何付款或加密货币转账被处理。',
    Done:'完成',

    settlementAmount: "Settlement amount: ",
    settlementWireInfo: "Settlement Wire Transfer Info: ",
    receivingWalletAddress: "Receiving wallet address: ",
    otcDirectUsername: "Client Name: ",
    conductorsName: "Conductor's Name: ",

    depositAmount: "Deposit Amount: ",
    depositWireInstru: "Deposit wire instructions: ",
    depositWalletAddress: "Deposit Wallet Address: ",
    quantityToSeller: "Quantity Deliverable to Seller: ",
    buyerWireTransferInstru: "Buyer's Wire Transfer Instructions: ",
    sellersWalletAddress: "Seller's Wallet Address: ",
    exRate: "Exchange Rate: ",
};
export default tradeConfirmation;
