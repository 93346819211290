const onboarding = {
    Optional: " (Optional)",
    submit:'Submit',

    // onboarding type
    chooseYourApplicationMethod:'Choose Your Application Method',
    onlineOnboarding:'Online Onboarding',
    applicationMethod:'Application Method',
    onboardingProcessMessage:'You can complete your onboarding process online or submit your application using the provided form.',
    formOnboarding:'Form Onboarding',
    start:'Start',
    entityOnboarding:'Entity Onboarding',
    individualOnboarding:'Individual Onboarding',
    noOnboardingFormMessage:'If you do not have an onboarding form yet, please download the form and checklist below for more onboarding information.',
    checklist:'Checklist',
    onboardingForm:'Onboarding form',
    download:'Download',
    unsavedChangesWarning:'If you leave or refresh this page, any changes will not be saved.',
    
    // form onboarding review
    confirmation:'Confirmation',
    submittedDocuments:'Submitted Documents',
    thankYouForUpdatingInfo:'Thank you for submitting your information with VirgoCX Direct Inc. One of our account managers will review your onboarding application and contact you if further information is needed. In the meantime, you can use the link below to check the status of your application, finish your submission, or reach out to us with your email information.',
    
    // account type
    accountType:'Account Type',
    accountTypeDesc:'We need to verify your identity according to the regulations so that you can start to trade safely.',
    pleaseSelectAccType:'Please select your account type',
    individualAcc:'Individual Account',
    entityAcc:"Entity Account",
    next:'Next',

    //memu
    menuDesc:'Please follow these steps to complete the application.',
    firstStep:'Submit Your Information',
    minutes:'Minutes',
    requiredBy:'Required by regulations',
    secondStep:'Acknowledge Statement',
    thirdStep:'Submit Photo ID',
    fourthStep:'Review and Submit',
    continue:'Continue',

    //welcome
    Welcome:'Welcome',
    welcomeDesc:'Before you start, please provide your information.',
    startNow:'Start Now',

    //entity
    subStep1:"1.  Submit information",
    subStep1c:'Please provide corporation information.',
    EntityAccount:'Entity Account',
    SignificantIndividual:'Significant Individual',
    Documents:'Documents',
    BeneficialOwnership:'Beneficial Ownership and Third Party Attestation',
    Disclosure:'Disclosure',
    Review:'Review',

    //A. corporation info
    corporateInfo:'A. Corporate Information',
    legalName:'Corporation Legal name',
    registrationNumber:'Incorporation Number',
    businessId:'Business Identification Number ',
    businessIdContent:'Business Identification Number, Tax Identification Number, or Equivalent',
    website:'Website URL (Optional)',
    corporateCountry:'Country of Formation',
    

    //B. contact info
    contactInfo:'B. Contact Information',
    Describe:'Describe business function, and / or products and services offered',
    requireSignature:'How many signatures are required to transact on the account?',
    BuildingNumber:"Building Number",
    StreetAddress:"Street Address",
    unitNumber:"Apt, Suite or Unit (Optional)",
    City:"City",
    country:'Country/Region',
    Province:"State / Province",
    PostalCode:"Postal Code / ZIP code",
    PhoneNumber:'Phone Number',
    email:'Email',
    coutryFormation:'Country Of Legal Formation',

    //C. Business Activities
    BusinessActivities:'C. Business Activities',
    Primary:'Primary',
    Secondary:'Secondary (Optional)',
    Tertiary:'Tertiary (Optional)',
    PrimarySrc:'Primary',
    businessActivitiesDes:'Describe business function, and/or products and services offered',

    //D. Business Registrations
    BusinessRegistrations:"D. Business Registrations",
    question1:"Is your company regulated or registered with any Federal, State, and/or Provincial regulator?",
    yes:'Yes',
    no:'No',
    question1Desc:"If yes, please provide the name(s) of body(ies) that oversees your company’s activities",
    OversightAuthorities:"Company Oversight Authorities",
    question2:"Does your company operate as a money service business or securities dealer?",
    question3:"If yes, then does your company utilize the OFAC/Canada/UN/local equivalent published lists of individuals/entities (Special Designated Nationals List/Designated Persons Listing and Sanction Laws) to whom/which you are prohibited from providing financial services or with whom/which you are prohibited from conducting financial transactions (e.g. suspected terrorists, money launderers)?",

    //E. Source of Fund
    titleE: "E. Source of Fund",
    sourceOfFundh:"Please select all applicable source(s) of funds:",
    income:"Income (Savings of Employment)",
    giftH:"Please provide the Gifter's information.",
    pastInvestmentH:"Please provide the Past investment's information.",
    investmentType:"Investment Type",
    investmentDate:" Investment Date (yyyy-mm-dd)",
    investmentAmount:"Investment Amount",
    investmentProfit:"Investment Profit",
    sofOther:"Please explain",
    Address:"Address",
    Relationship:"Relationship",
    JobTitle:"Job Title",
    Employer:"Employer",
    Income:"Income",
    JobFunction:"Job Function",
    SourceofFunds:"Source of Funds",
    GiftValue:"Gift Value",
    GiftPurpose:"Gift Purpose",
    DateofBirth: "Date of Birth (yyyy-mm-dd)",

    //F. Purpose of Account
    titleF: "F. Purpose of Account",
    FriendlyReminder:'Friendly Reminder',
    modalTxt:'Once you move to next section, you will no longer be able to change your application type.',
    confirm:'Confirm',
    changeMind:'I changed my mind',

    //2.  Significant Individual
    SignificantIndividual:"2.  Significant Individual",
    save:'Save',
    next:'Next',
    formEmpty:'Please complete the current form first before adding more.',
    NewIndividual:"New Individual",
    areYousure:'Are you sure you want to delete this individual?',
    pleaseConfirm:"Please Confirm All Information Is Complete",
    kindlyConfirm:'Kindly confirm that all information is complete before submitting.',
    sthMissing:'Something is missing',
    toAddPerson:'To add a new individual, please ensure all personnel information is complete. ',
    ok:'Ok',
    addNewPerson:'Add More Person',
    toSubmit:'To submit the form, please ensure all personnel information is complete.',


    //3. documents
    Documents:'3. Documents',
    step3subtitle:"Please provide documents as instructed.",
    docRequire:'Documents Required',
    uploadAll:'Please upload all necessary documents here:',
    fileOneTitle:'Organization Chart / Corporation Ownership / Control Structure',
    fileOneSubTitle:'All beneficial owners and directors mentioned in entity onboarding form must show in this chart, the total should be 100% shares of the corporation.',
    fileTwoTitle:'Shareholders Agreement',
    fileTwoSubtitle:'Must include all current shareholder’s names and their signatures.',
    fileThreeTitle:'Schedule 50',
    fileThreeSubtitle:'Normally requested by CRA.',
    fileFourTitle:'Board of Resolution',
    fileFourSubtitle:'Different for each entity, please ask Account Manager for more details.',
    fileFiveTitle:'Articles of Incorporation / Certificate of Incorporation',
    fileFiveSubtitle:'Issued by the local government, which includes corporate name, number, address, and directors’ information.',
    fileSixTitle:'Master Trading Agreement (Optional)',
    fileSixSubtitle:'Including trading rules, privacy and all other related terms, client who has retail account and / or wants to use our direct platform must sign this.',
    fileSevenTitle:'AML / KYC Policy (Optional)',
    fileSevenSubtitle:'This is required for all MSB entities and financial institutions. If you are a money service business (MSB) or financial institution which holds client’ assets, please provide us your AML/KYC policy.',
    fileEightTitle:'Financial account wire information',
    fileEightSubtitle:'It can be the bank pre-authorized payment form, void cheque or anything include information with bank name & address, your name & address, institution number, transit number, account number and SWIFT code.',
    fileNineTitle:'Support Documents (Optional)',
    fileNineSubtitle:'Amendment form, information explanation, investment record, bank statement and any information you want to add to support your trading.',
    Attention:'Attention',
    attention1: 'All files above in PDF version must directly from original sources, modified files will NOT be accepted.',
    attention2: 'For file 3-5, must include corporate stamp/ signature or write on the corporate letterhead page. Please only provide one document between File 3, File 4 and File 5.',
    attention3: 'Please make sure name and address in File 1 are matched with File 8, otherwise, please provide support documents in file 9 to explain why the name and/or address are different. Please only provide one document between File 3, File 4 and File 5.',
    attention4: 'All people’s name listed in files must provide ID for verification, we will send Jumio link after receiving these files.',
    attention5: 'Contact your Account Manager for any questions you may have.',
    
    

    // 4. Beneficial Ownership and Third Party Attestation
    entityStep4LH:"4. Beneficial Ownership and Third Party Attestation",
    Attestation:"Attestation",
    Attestation1_1_0:"I, ",
    Attestation1_1:" have made, or caused to be made, such examinations or investigations as are, in my opinion, necessary to make the statements contained in this certificate and I have furnished this certificate with the intent that it may be relied on by VirgoCX Direct Inc.",
    Attestation1_2:"Attached to this certificate are true, complete and accurate copies of:",
    Attestation1_2_a_1:"the shareholders’ register of the Corporation, (the Shareholders Register ); and",
    Attestation1_2_b_1:"an organizational chart of the Corporation setting out the ownership and control structure of the Corporation (the Organizational Chart).",
    Attestation1_3:"The Organizational Chart sets out the true, complete and current information on the ownership and control of the Corporation.",
    Attestation2:"The Shareholders’ Register contains a true, complete and current record of all registered holders of securities and warrants issued by the Corporation. The Corporation:",
    Attestation2_1:"has not given to any other person any conversion privileges, options or other rights to acquire any securities of the Corporation:",
    Attestation2_2:"has given conversion privileges, options or other rights to acquire any securities of the Corporation to the following persons:",
    Attestation2_2_input:"Specify details",
    Attestation3_1:"The Corporation does not issue bearer shares. If the Shareholders’ Register shows any registered holder of securities or warrants that is not an individual and that owns 25% or more of the shares of the Corporation, attached to this certificate are also the shareholders’ register (or equivalent) of any such threshold entity (and of any entity controlling such threshold entity), which shareholders’ register contains a true, complete and current record of all registered holders of securities and warrants issued by the entity. ",
    Attestation3_2:"To the extent that the attachments to this form include personal information, I have obtained all necessary consents from the individuals about whom such information relates to allow VirgoCX Direct Inc. to collect, use and disclose the personal information for the purposes described in the VirgoCX Direct Inc. Privacy Policy. ",
    Attestation4:"In its dealings with VirgoCX Direct Inc: ",
    Attestation4_1:"the Corporation is not acting on behalf of a third party;",
    Attestation4_2:"the Corporation is acting on behalf of third parties",
    disclosureT:"5. Disclosure",

    // 6. Review
    reviewH:"6. Review",
    reviewC:"Kindly provide your personal information.",
    edit:"Edit",
    reviewFile:'Review File',
    individual:'Individual',
    submitApplication:'Upload Application',

    //success page
    Successfully:'Uploaded Successfully',
    refNumber:'Your Reference Number: ',
    successContent:"Thanks for uploading the onboarding request to VirgoCX Direct Inc. One of our account managers will review your onboarding information and give you a call back if they need further information. If all the information is good, you can use the link below to sign and submit your application directly. Please remember your Reference Number since it is important for checking your application status or finishing your final submit.  ",
    CustomerService:'Our Customer Service Number',
    businessHours:'Our business hours',
    hours:'Mon to Fri: 9:00 am - 5:00 pm',
    CheckStatus:'Check My Status',

    // onboarding type modal
    readyToCompleteOnboarding:'Ready to Complete Your Onboarding?',
    onboardingTypeModalH:'Before you proceed, please ensure that you have the following ready:',
    onboardingTypeModal1:'Completed onboarding form',
    onboardingTypeModal2:'Relevant documents (e.g., identification, proof of address)',
    onboardingTypeModal3:'Any additional information required',


    //validate message
    enterEmail:"Please enter the Email.",
    formatIncorrect:"The email format is incorrect.",
    Back:'Back'

};
export default onboarding;