const personalInfo = {
    test: "test",
    subStep1: "1.提交您的信息",
    subStep1c: "请提供您的个人信息",
    Back: "返回",
    PersonalInformation: "A. 个人信息",
    FirstName: "名字",
    LastName: "姓氏",
    PhoneNumber: "电话号码",
    Email: "电子邮件",
    DateofBirth: "出生日期（年-月-日）",
    Nationality: "国籍",
    APP: "即时通讯软件",
    otherAPP: "其他应用",
    APPID: "通讯软件 ID",
    Optional: " （可选）",
    Next: "下一项",
    Submit:"提交",

    titleB: "B. 住址信息",
    BuildingNumber:"大楼号码",
    StreetAddress:"街道地址",
    unitNumber:"公寓门牌号，单元号（选填）",
    City:"城市",
    country:'国家/地区',
    Province:"州/省",
    PostalCode:"邮政编码",
    hasMailingAddress:"Is your mailing address the same as your residential address?",
    Yes:"是",
    No:"否",

    titleC: "E. 在职信息",
    PrimaryEmployment:"当前/最新就业情况",
    CurrentEmploymentStatus: "当前就业状态",
    YearStart: "开始年份",
    YearEnd: "结束年份",
    now:"现在",
    Industry: "行业",
    Countryofemployment: "就业国家",
    Nameofemployer: "雇主名称",
    TitleRole: "职位名称",
    Department: "所在部门",
    EmployerWebsite: "雇主网站",
    SalaryRange: "薪资范围",
    OtherEmployment: "其他就业信息",
    SecondaryEmployment: "之前就业信息",

    titleD: "C. 资金来源",
    sourceOfFundh:"请选择所有适用的资金来源",
    income:"收入（工作储蓄）",
    giftH:"请提供赠予者的信息",
    pastInvestmentH:"请提供过去投资的信息",
    investmentType:"投资类型",
    investmentDate:"投资日期（年-月-日）",
    investmentAmount:"投资金额",
    investmentProfit:"投资收益",
    sofOther:"请解释",
    Address:"居住地址",
    Relationship:"和客户的关系",
    JobTitle:"工作职位",
    Employer:"雇主名称",
    Income:"收入",
    JobFunction:"工作职能",
    SourceofFunds:"资金来源",
    GiftValue:"赠予金额",
    GiftPurpose:"赠与目的",


    titleE: "D. 账户用途",
    FriendlyReminder:'友情提醒',
    modalTxt:'一旦您进入到下个部分，您将无法再更改您的开户形式.',
    confirm:'确认',
    changeMind:'我再想想',
    later:`稍后`,
    modalJumio:`在您点击“确认”之后，回导向新的页面用于完成您的身份验证步骤`,

    titleF: "F. 声明",
    q1:"您是否正在或曾经受到联合国、任何自我监管组织、任何国家当局或其他金融监管机构的制裁?",
    q2:"您是否被视为政治公众人物，外国政要、本国政要、国际组织政要及其亲属或关系密切人士 (统称为政治公众人物)?",
    yes:"是",
    no:"否",
    Fconfirm1:"我，",
    Fconfirm2:"，声明任何法币或加密货币的转账指令都将来自于我拥有和控制的帐户或钱包。 除此之外，我明确不会使用我的帐户进行或代为第三方交易。",

    subStep2: "2. 身份验证",
    subStep2c: "请选择您的身份验证方式",
    subStep2manual:"请按照要求提供您的文件",
    verificationTitle:"请选择以下方式之一并按照相应步骤完成您的身份验证",
    OnlineIdentityVerification:"在线身份验证",
    ManualVerification:"手动身份验证",
    InPersonVerification:"亲自身份验证",
    DocumentsRequired:"所需文件",
    manualC:"请按照要求提交您的所需文件",
    file1:"身份证明 （护照、驾照正反面或其他政府签发的身份证件）",
    file2:"地址证明（三个月内的原始PDF版本水电费账单或银行对账单）",
    file3:"其他相关文件",

    subStep3: "3. 条款",
    subStep3c: "请阅读并同意以下条款",
    Agree:"同意",
    acknowledgeError: `请下滑到底，阅读所有文件内容，并点击同意`,

    subStep4: "3. 审核",
    subStep4c: "请提供您的个人信息",

    edit:"编辑",
    cancel:"取消",
    reviewFile:"查看文件",
    submitApplication1:"上传",
    accepted:"已接受",
    Submitted:"已提交",

    NewIndividual:"添加个人信息",
    formTitle:'请提供以下信息',
    middleName:"中间名（可选）",
    address:"居住地址",
    email:"电子邮件",
    formTitle2:"此个人是否符合FATF中定义的政治公众人物（PEP）",
    relevantroles:"请选择所有相关身份",
    Reminder:"提示",
    BeneficialOwners:'公司受益人',
    BeneficialOwnersContent:'请提供所有直接或间接拥有25%或更多股份的股东信息。如果公司的任何股东是由某家公司控股，则请附上该公司的组织架构图',
    Director:"董事",
    AuthorizedOfficer:'授权签字人',
    confirmAuthorizedOfficer:"请确认授权签字人",
    AuthorizedTrader:"授权交易人",
    IndividualWithControl:"有重大控制权的个人",
    IndividualWithControlContent:"拥有重大控制权的个人定义为具有以下一项或多项特征的个体：拥有、控制或指导大量股份；或在没有拥有任何股份的情况下对公司产生重大影响；或具有上述任何因素的组合。请注意，如果您在此申请中已选择过其他身份，则无需选择此项。",
    Ownership:"占股百分比",
    Occupation:'所担职位',


    //entity person
    Individualverification:"个人身份验证",
    individualSubTitle:'请选择以下方法之一验证您的身份，并按照适当的步骤操作：',
    otherIdVerification:"在线身份验证",
    manual:'手动身份验证',
    inPerson:'亲自身份验证',
    uploadDocs:'上传文件',
    Identification:'身份识别',
    individualSubTitle2:'护照、驾照正反面或其他政府签发的身份证明',
    ProofofAddress:'地址证明',
    individualSubTitle3:'三个月内的原始PDF版本水电费账单或银行对账单',
    Methods:'身份验证办法',
    idVerification:'身份证明 （护照、驾照正反面或其他政府签发的身份证件）',
    proofOfAddressTitle:'地址证明（三个月内的原始PDF版本水电费账单或银行对账单）',
    submitApplication:'上传申请',

    // id verification des:
    idVerificationMethod:'身份验证方式',
    chooseVerificationMethod:'请选择身份验证方式。',
    onlineDes:`在您提交文件后会自动生成一个链接，请点击该链接并按照指示完成身份验证。`,
    manualDes:'接下来将需要您提供身份证明及地址证明的相关文件。',
    inPersonDes:'此选项仅适用于您已与我们的客户经理会面或计划进行会面的情况；否则，请选择上述两种方式中的任意一种。',
    uploadRequiredFiles:'请上传所需文件',
    onboardingForm:'开户表格',
    uploadDocuments:'上传文件',
    providePersonalInformation:'请提供您的个人信息。',
    idVerificationT:'身份验证',
    proofOfAddress:'地址证明',
    supportDocumentsOptional:'其他相关文件（可选）',
    onboardingFormTip:"VirgoCX Direct Individual/Entity Onboarding Form, please make sure you filled all mandatory fields and signed",
    idTip:"Both front and/or back side of your Driver License, Passport or other government issued ID",
    poaTip:"Original PDF version utility bill or bank statement under your name and address within 3 months",
    supTip:'所有其他文件',

    // company docs
    companyDocs: "公司相关文件",
    companyDocsReminder: "请上传公司的组织结构图、股东协议、公司章程和（或）其他同等文件,详情请联系您的客户经理或直接致电我们。",

    // bank information
    bankInfo: "银行信息",
    bankInfoReminder: "银行电汇信息（请向您的客户经理了解不同国家的要求）"
    
};
export default personalInfo;
