import { Link } from "react-router-dom";
import {  useState,useEffect } from "react";
import { Dropdown,Drawer } from "antd";
import useBaseStore from "store/base";
import logo from "static/imgs/common/Logo.png";
import flag from "static/imgs/common/i18n.png";
import arrow_down from "static/imgs/common/arrow_white_down.svg";
import accLogo from "static/imgs/common/accLogo.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMember from "store/member";
import useFormOnboardingStore from "store/formOnboarding";
import menuBar from 'static/imgs/common/menuBar.png'
import cross from "static/imgs/common/cross.png"
import arrowDownGrey from 'static/imgs/common/downArrowGrey.png'
import './styles/header.css'

const HeaderSimple = () => {
    const { lang, changeLang } = useBaseStore();
    const { i18n } = useTranslation();
    const { t } = useTranslation("common");
    const navigate = useNavigate()
    const [visible,setVisible] = useState(false)
    const [windowWidth,setWindowWidth] = useState()//屏幕宽度
    const { memberInfo, resetMemberInfo,logout } = useMember()
    const { resetFormOnboarding }=useFormOnboardingStore();
    const switchLang = (r) => {
        if (r !== lang) {
            changeLang(r);
            i18n.changeLanguage(r);
            localStorage.setItem("language", r);
        }
    };

    // /是否是测试服
    const origin_url=window.location.origin.toString();
    const isTest = origin_url.includes('test') || origin_url.includes('wordpress') || origin_url.includes('localhost');
    const url = isTest ? 'https://catest.virgocx.ca/':'https://virgocx.ca/'

    const items = [
        {
            label: <div onClick={() => switchLang("en")}>English</div>,
            key: "0",
        },
        {
            label: <div onClick={() => switchLang("zh")}>中文</div>,
            key: "1",
        },
    ];

    const accountTabList = [
        {
            label: <div onClick={() => navigate('/dashboard')}>{t('myAcc')}</div>,
            key: "0",
        },
        {
            label: <div onClick={() => handleLogout()}>{t('logout')}</div>,
            key: "1",
        }, 
    ]

    const resizeUpdate = (e) => {
        // 通过事件对象获取浏览器窗口的高度
        let w = e.target.innerWidth;
        setWindowWidth(w);
    };
 
    useEffect(() => {
        // 页面刚加载完成后获取浏览器窗口的大小
        let w = window.innerWidth;
        setWindowWidth(w)
 
        // 页面变化时获取浏览器窗口的大小 
        window.addEventListener('resize', resizeUpdate);
 
        return () => {
            // 组件销毁时移除监听事件
            window.removeEventListener('resize', resizeUpdate);
        }
    }, []);

    useEffect(() => {
        if (windowWidth && windowWidth >= 640) {
            setVisible(false)
        }
    }, [windowWidth])



    const handleLogout = () =>{
        setVisible(false)
        logout().then(()=>{
            resetMemberInfo()
            resetFormOnboarding()
            navigate('/')
        })
    }

    const handleMyaccount = () => {
        setVisible(false)
        navigate('/dashboard')
    }
    

    return (
        <div className="w-full sm:min-w-[1440px] bg-[#05004D] ">
            <div className="z-10 w-full fixed sm:relative px-[16px] py-[20px] sm:px-[140px] sm:py-[32px] bg-[#05004D] flex justify-between items-center sm:w-[1440px] sm:mx-auto">
                <Link to="/">
                    <img src={logo} className="w-[160px] sm:w-[214px]" alt="logo" />
                </Link>
                <div className="hidden sm:flex sm:justify-between sm:items-center sm:w-[500px] text-white text-[16px] cursor-pointer">
                    <a target="_blank" href={`${url}${lang}-otc-direct`} className="hover:underline underline-offset-4">{t('OTCDirect')}</a>
                    <a target="_blank" href={`${url}${lang}-otc-chatbroker`} className="hover:underline underline-offset-4">{t('ChatBroker')}</a>
                    <a target="_blank" href={`https://learn.virgocx.ca/`} className="hover:underline underline-offset-4">{t('Learn')}</a>
                    <a target="_blank" href={`${url}${lang}`} className="hover:underline underline-offset-4">Virgocx.ca</a>
                    <a target="_blank" href={`${url}${lang}-otc-referral`} className="text-[#C7BA9A] rounded-[50px] px-[14px] py-[2px] border-[1px] border-[#c7ba9a]">{t('Earn$200')}</a>
                </div>
                <img onClick={()=>setVisible(true)} src={menuBar} className="sm:hidden block w-[27px] h-[27px]" alt="logo" />
                <div className="items-center justify-between hidden sm:flex">
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={["click"]}
                    >
                        <div className="flex justify-between items-center gap-[4px] text-white text-[16px] cursor-pointer hover:underline underline-offset-2">
                            <img src={flag} alt="flag" className="w-[16px]" />
                            <div>{lang === "en" ? "English" : "中文"}</div>
                            <img src={arrow_down} alt="arrow_down" className="w-[16px]" />
                        </div>
                    </Dropdown>
                    {
                        localStorage.wealthAuth &&
                        <Dropdown
                            menu={{
                                items: accountTabList,
                                defaultSelectedKeys: ['0'],
                            }
                            }
                            trigger={["click"]}
                        >
                            <div className='ml-[30px] flex justify-between items-center gap-[4px] text-white text-[16px] cursor-pointer hover:underline underline-offset-2'>
                                <img src={accLogo} alt="flag" className="w-[24px]" />
                                <div>{t('myAcc')}</div>
                                <img src={arrow_down} alt="arrow_down" className="w-[16px]" />
                            </div>
                        </Dropdown>
                    }    
                </div>
            </div>
            {/* place holder for mobile */}
            <div className="w-full h-[66.7px] sm:hidden"></div>
            <div className="block sm:hidden">
                <Drawer
                    placement='right'
                    open={visible}
                    onClose={() => setVisible(false)}
                    closeIcon={false}
                    destroyOnClose={true}
                    getContainer={false}
                    bodyStyle={{paddingTop:80,paddingLeft:16,paddingRight:16}}
                >
                    <div className="modal-container sm:!w-full sm:!min-h-[368px] sm:relative">
                        <div className="flex items-center justify-between">
                            <h1>{t('welcome')}</h1>
                            <img onClick={() => setVisible(false)} src={cross} alt="cross" className="cursor-pointer w-[24px] h-[24px]" />
                        </div>
                        <hr className="!my-[24px]"></hr>
                        <div className="flex flex-col gap-y-[24px] h-[225px] ">
                            <a target="_blank" href={`/${lang}-otc-direct`} className="hover:underline underline-offset-4"><p className="pl-[8px] text-[18px] text-[#05004D]">{t('OTCDirect')}</p></a>
                            <a target="_blank" href={`/${lang}-otc-chatbroker`} className="hover:underline underline-offset-4"><p className="pl-[8px] text-[18px] text-[#05004D]">{t('ChatBroker')}</p></a>
                            <a target="_blank" href={`https://learn.virgocx.ca/`} className="hover:underline underline-offset-4"><p className="pl-[8px] text-[18px] text-[#05004D]">{t('Learn')}</p></a>
                            <a target="_blank" href={`/${lang}`} className="hover:underline underline-offset-4"><p className="pl-[8px] text-[18px] text-[#05004D]">Virgocx.ca</p></a>
                            <a target="_blank" href={`/${lang}-otc-referral`} className="w-[116px] h-[35px] text-[#C7BA9A] rounded-[50px] text-center py-[5px] border-[1px] border-[#c7ba9a]"><p className="text-[16px]">{t('Earn$200')}</p></a>
                        </div>
                        <hr className="!my-[24px]"></hr>
                        {localStorage.wealthAuth &&
                            <div className="flex flex-col gap-y-[24px]">
                                <p  onClick={handleMyaccount} className={ `pl-[8px] ${window.location.href.includes('dashboard')?"border-l-[#C7BA9A] border-l-2":""} text-[18px]`}>{t('myAcc')}</p>
                                <p onClick={handleLogout} className="pl-[8px] text-[18px]">{t('logout')}</p>
                                <hr className="mb-[24px]"></hr>
                            </div>
                        }
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={["click"]}
                        >
                            <div className="flex justify-between items-center gap-[4px] text-white text-[16px] cursor-pointer hover:underline underline-offset-2">
                                <div className="flex items-center justify-start">
                                <img src={flag} alt="flag" className="w-[16px]" />
                                <p className="text-[16px] ml-[5px] text-[#000000]">{lang === "en" ? "English" : "中文"}</p>
                                </div>
                                <img src={arrowDownGrey} alt="arrow_down" className="w-[24px]" />
                            </div>
                        </Dropdown>
                    </div>
                </Drawer>
            </div>
        </div>
    );
};
export default HeaderSimple;
